
import React, { Component } from 'react';
import styled from 'styled-components';

const StyledSection = styled.section`
    padding: 20px 200px; /* 20px top and bottom, 40px left and right */
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;

    @media (max-width: 768px) {
        padding: 10px 20px; /* Adjust padding for smaller screens */
    }
`;

const FlowChartContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
`;

const FlowChartBox = styled.div`
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    background: #fff;
    position: relative;
    width: 45%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const Separator = styled.div`
    width: 80%;
    height: 1px;
    background-color: #ccc;
    margin: 10px 0;
`;

const Heading = styled.h2`
    color: #1b3454;
    font-size: 1.5em;
    margin-bottom: 20px;
`;

const Text = styled.p`
    color: #666;
    font-size: 1em;
    line-height: 1.5;
`;

class Services extends Component {
    state = {
        companyInfo: {
            title: 'InfiVidhya – Your Trusted Partner in Engineering Services, Staffing & Resourcing',
            description: 'At InfiVidhya, we specialize in providing cutting-edge engineering solutions, expert staffing services, and strategic resourcing to empower businesses with top-tier talent and innovative solutions. With a strong commitment to quality, efficiency, and excellence, we ensure that your organization meets its operational and technical demands seamlessly.',
            services: [
                { title: 'Engineering Services', text: 'Our team of experienced engineers and domain specialists deliver tailored, technology-driven solutions to optimize performance, reduce costs, and enhance efficiency. We offer expertise in Product Design & Development, Process Optimization & Automation, AI & Data Science Solutions, Engineering Consultancy & Training, and Technical Documentation & Compliance.' },
                { title: 'Staffing Solutions', text: 'Finding the right talent is critical to business success. We provide highly skilled professionals across various technical domains. Our staffing services include Permanent Hiring, Contract Staffing, Project-Based Staffing, and Executive Search for leadership positions.' },
                { title: 'Resourcing & Workforce Augmentation', text: 'Stay ahead of the competition with our comprehensive resourcing solutions, ensuring access to the best industry talent. Our services include Onshore & Offshore Workforce Management, Customized Recruitment Strategies, Skill-Based Talent Acquisition, and Training & Upskilling Programs.' }
            ],
            whyChooseUs: [
                { title: 'Quality & Expertise', text: 'Our team comprises industry veterans, domain experts, and technology specialists who ensure high-quality deliverables tailored to your business needs.' },
                { title: 'Timely & Efficient Execution', text: 'We understand the importance of time-sensitive projects and adhere to strict deadlines without compromising on quality.' },
                { title: 'Cost-Effective & Scalable Solutions', text: 'Our services are designed to be scalable, flexible, and budget-friendly, ensuring maximum ROI for our clients.' },
                { title: 'Industry-Specific Customization', text: 'We cater to various industries, including IT, Manufacturing, Healthcare, Automotive, and more, delivering customized, sector-specific solutions.' }
            ],
            getStarted: 'Partner with InfiVidhya for seamless, reliable, and high-performance engineering services, staffing, and resourcing. Let’s collaborate to drive innovation, boost efficiency, and achieve business excellence.'
        }
    };

    renderSection = (sectionTitle, items) => (
        <>
            <Heading>{sectionTitle}</Heading>
            <FlowChartContainer>
                {items.map((item, index) => (
                    <FlowChartBox key={index}>
                        <Heading>{index + 1}. {item.title}</Heading>
                        <Separator />
                        <Text>{item.text}</Text>
                    </FlowChartBox>
                ))}
            </FlowChartContainer>
        </>
    );

    render() {
        const { companyInfo } = this.state;

        return (
            <StyledSection>
                <FlowChartContainer>
                    <FlowChartBox style={{ width: '100%' }}>
                        <Heading>{companyInfo.title}</Heading>
                        <Separator />
                        <Text>{companyInfo.description}</Text>
                    </FlowChartBox>
                </FlowChartContainer>
                {this.renderSection('Our Core Offerings', companyInfo.services)}
                {this.renderSection('Why Partner with Us?', companyInfo.whyChooseUs)}
                <FlowChartContainer>
                    <FlowChartBox style={{ width: '100%' }}>
                        <Heading>Get Started Today</Heading>
                        <Separator />
                        <Text>{companyInfo.getStarted}</Text>
                    </FlowChartBox>
                </FlowChartContainer>
            </StyledSection>
        );
    }
}

export default Services;
