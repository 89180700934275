import React from 'react';
import { PageBanner, Features } from '../layouts/Services/index';
import Layout from '../../common/Layout';
import ContactForm from '../layouts/contact/ContactForm';



const Services = () => {
    return (
        <div className="about">
            <Layout>
                <PageBanner
                    title="Services offered by InfiVidhya"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Services"
                />
                <Features />
                <div className="py-5">
                    <ContactForm />
                </div>

            </Layout>
        </div>
    );
}

export default Services;