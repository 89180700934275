import React, { useRef, useEffect, useState } from "react";

const Nav = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const spanElement = document.createElement("span");
      spanElement.className = "menu-trigger";
      spanElement.innerHTML = '<i class="fal fa-angle-down"></i>';
      containerRef.current.appendChild(spanElement);
    }
  }, []);

  const [menuItems, setMenuItems] = useState([
    { id: 1, label: "Home", link: "/", class: "current" },
    { id: 2, label: "About", link: "/about" },
    { id: 3, label: "Courses", link: "/course" },
    { id: 4, label: "Instructors", link: "/instructors" },
    { id: 5, label: "Research", link: "/Research" },
    { id: 6, label: "Services", link: "/Services" },
    { id: 7, label: "Events Happened", link: "/EventsHappened" },
    { id: 8, label: "Contact", link: "/contact" },
  ]);
  useEffect(() => {
    setMenuItems((prevMenuItems) =>
      prevMenuItems.map((item) =>
        item.submenu && item.submenu.length > 0
          ? { ...item, hasSubmenu: true }
          : item
      )
    );
  }, []);

  const handleSubmenuToggle = (index) => {
    setMenuItems((prevMenuItems) => {
      const newMenuItems = [...prevMenuItems];
      newMenuItems[index].submenuOpen = !newMenuItems[index].submenuOpen;
      return newMenuItems;
    });
  };

  return (
    <ul className="primary-menu">
      {menuItems.map((item, index) => (
        <li key={item.id}>
          <a href={item.link}>{item.label}</a>

          {item.submenu && (
            <button
              className={`menu-trigger ${item.submenuOpen ? "open" : ""}`}
              onClick={() => handleSubmenuToggle(index)}
              aria-expanded={item.submenuOpen}
              aria-controls={`submenu-${item.id}`}
            >
              <i
                className={`submenu-icon ${
                  item.submenuOpen ? "fal fa-angle-up" : "fal fa-angle-down"
                }`}
              ></i>
            </button>
          )}
          {item.submenu && (
            <ul className={`submenu ${item.submenuOpen ? "open" : ""}`}>
              {item.submenu.map((subitem, subindex) => (
                <li key={subitem.id}>
                  <a href={subitem.link}>{subitem.label}</a>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};
export default Nav;
