import "./App.css";
import React, { useEffect } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { ModalProvider } from "styled-react-modal";

import AdminFormEntries from "./AdminFormEntries";
import HomeTwo from "./components/pages/Home";
import AboutMain from "./components/pages/About";
import CourseTwo from "./components/pages/CourseTwo";
import InstructorOne from "./components/pages/InstructorPage";
import Research from "./components/pages/Research";
import ContactPage from "./components/pages/ContactPage";
import Internshippage from "./components/pages/internship";
import EventsHappened from "./components/pages/eventsHappned";
import Services from "./components/pages/Services";

function App() {
  useEffect(() => {
    // Disable right-click
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    // Disable copying
    const handleCopy = (event) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("copy", handleCopy);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("copy", handleCopy);
    };
  }, []);

  return (
    <ModalProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<HomeTwo />} />
          <Route exact path="/about" element={<AboutMain />} />
          <Route exact path="/course" element={<CourseTwo />} />
          <Route exact path="/instructors" element={<InstructorOne />} />
          <Route exact path="/contact" element={<ContactPage />} />
          <Route exact path="/internship" element={<Internshippage />} />
          <Route exact path="/EventsHappened" element={<EventsHappened />} />
          <Route exact path="/Research" element={<Research />} />
          <Route exact path="/Services" element={<Services />} />
          <Route exact path="/admin_formentry" element={<AdminFormEntries />} />
        </Routes>
      </BrowserRouter>
    </ModalProvider>
  );
}

export default App;
