import React, { useState, useEffect } from "react";
import {
  Banner,
  Counter,
  CourseCat01,
  Process,
  Features,
  Review,
  Cta,
  MarqueeComponent,
} from "../layouts/home02/index";
import HeaderTwo from "../../common/header/Header";
import FooterTwo from "../../common/footer/Footer";
import Marquee from "react-fast-marquee";
import StyledModal from "styled-react-modal";
import { FaCheckCircle } from "react-icons/fa";
import "./HomeTwo.css"; // Import the CSS file

const StyledCardModal = StyledModal.styled`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.2);
  width: 450px;
  max-width: 90%;
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;

  @keyframes fadeIn {
    from { opacity: 0; transform: scale(0.9); }
    to { opacity: 1; transform: scale(1); }
  }
  
  .modal-icon {
    color: #28a745;
    font-size: 80px;
    margin-bottom: 15px;
  }

  h2 {
    font-size: 26px;
    color: #333;
    margin-bottom: 12px;
  }

  p {
    font-size: 18px;
    color: #555;
    margin-bottom: 25px;
  }

  .contact-btn {
    display: inline-block;
    padding: 12px 24px;
    background: #28a745;
    color: white;
    text-decoration: none;
    border-radius: 8px;
    font-size: 18px;
    font-weight: bold;
    transition: background 0.3s ease;
  }

  .contact-btn:hover {
    background: #218838;
  }

  /* Responsive Design */
  @media (max-width: 600px) {
    padding: 20px;
    
    .modal-icon {
      font-size: 60px;
    }

    h2 {
      font-size: 22px;
    }

    p {
      font-size: 16px;
    }

    .contact-btn {
      font-size: 16px;
      padding: 10px 20px;
    }
  }
`;

const HomeTwo = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleModal = () => setIsOpen(!isOpen);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  return (
    <div className="home-page-2">
      <Marquee className="custom-marquee">
        Data Science and Full Stack Courses are currently running! Limited Seats
        Available!
      </Marquee>
      <HeaderTwo />
      <Banner />
      <Features />
      <CourseCat01 />
      <Counter />
      <Cta />
      <Process />
      <Review />
      <MarqueeComponent />
      <FooterTwo />

      <StyledCardModal
        isOpen={isOpen}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
      >
        <FaCheckCircle className="modal-icon " />
        <h2>You've availed the discount!</h2>
        <p>
          Click the button below to contact us and activate the offer. Please
          fill the form following to avail the exclusive offer.
        </p>

        <a href="/contact" className="contact-btn">
          Activate Offer
        </a>
      </StyledCardModal>
    </div>
  );
};

export default HomeTwo;
